/* eslint no-unused-vars: 0 */

import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import React, { Component } from "react";

const FormItem = Form.Item;
import "antd/lib/form/style/index.css";
import "antd/lib/modal/style/index.css";
import "antd/lib/input/style/index.css";
import "antd/lib/button/style/index.css";
import { ThemeContext } from "../../layouts";

class Submission extends Component {
  state = {
    visible: false
  };

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.sendMessage(values);
      }
    });
  };

  sendMessage = ({ email, firstName, lastName }) => {
    fetch("https://formspree.io/f/myybvpdg", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: this.encode({
        "fields[email]": email,
        "fields[first_name]": firstName,
        "fields[last_name]": lastName
      })
    })
      .then(() => {
        console.log("Form submission success");
        window.location.href = "https://www.commonality.me";
      })
      .catch(error => {
        console.error("Form submission error:", error);
        // handleNetworkError(error);
        window.location.href = "https://www.commonality.me";
      });
  };

  showModal = e => {
    e.preventDefault();
    this.toggleModal();
  };

  handleNetworkError(e) {
    console.log("submit Error", e);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <div className="form">
              <Modal
                closable={false}
                footer={false}
                visible={this.state.visible}
                title="We'd love to connect with you:"
              >
                <Form
                  action="https://formspree.io/f/myybvpdg"
                  name="contact"
                  onSubmit={this.handleSubmit}
                  data-drip-embedded-form="361007720"
                  method="post"
                  className="form-modal"
                >
                  <FormItem label="First Name:">
                    {getFieldDecorator("firstName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your first name!",
                          whitespace: true
                        }
                      ]
                    })(<Input name="firstName" />)}
                  </FormItem>
                  <FormItem label="Last Name:">
                    {getFieldDecorator("lastName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your last name!",
                          whitespace: true
                        }
                      ]
                    })(<Input name="lastName" />)}
                  </FormItem>
                  <FormItem label="Email:">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your e-mail address!",
                          whitespace: true,
                          type: "email"
                        }
                      ]
                    })(<Input name="email" />)}
                  </FormItem>

                  <FormItem>
                    <Button type="primary" className="contact-submit-btn" htmlType="submit">
                      REQUEST ACCESS
                    </Button>
                  </FormItem>
                </Form>
              </Modal>
              <Form
                action="https://www.getdrip.com/forms/361007720/submissions"
                layout="inline"
                name="contact"
                onSubmit={this.showModal}
                data-drip-embedded-form="361007720"
                method="post"
              >
                <FormItem label="Connect with us:" className="form-label">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your e-mail address!",
                        whitespace: true,
                        type: "email"
                      }
                    ]
                  })(<Input name="email" />)}
                </FormItem>
                <FormItem  className="form-label">
                  <Button type="primary" className="contact-submit-btn" htmlType="submit">
                    REQUEST ACCESS
                  </Button>
                </FormItem>
              </Form>
              {/* --- STYLES --- */}
              <style jsx>{`
                .form {
                  background: transparent;
                  margin-top: 30px;
                }
                .form-label {
                  margin-top: 30px;
                }
                :global(.contact-submit-btn) {
                  background-color: #f28500 !important;
                  border-color: #f28500 !important;
                  font-size: 1.2em;
                  padding: 0.5em 3em;
                  
                }

                :global(.ant-form-item-control) {
                  line-height: 1em;
                }
                :global(.ant-form-item-label) {
                  margin-bottom: 0.5em;
                }
                :global(.ant-input) {
                  appearance: none;
                  height: auto;
                  font-size: 1.2em;
                  padding: 0.5em 0.6em;
                }
                .form :global(.ant-form-item-label label) {
                  color: #fff;
                }

                .form :global(.ant-form-item-label label),
                .form-modal :global(.ant-form-item-label label) {
                  font-size: 1.4em;
                  font-weight: 500;
                  line-height: 1.4em;
                }

                :global(.ant-btn-primary) {
                  height: auto;
                  font-size: 1.2em;
                  padding: 0.5em 3em;
                }
              `}</style>
            </div>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  }
}

Submission.propTypes = {
  form: PropTypes.object
};

const SubmissionForm = Form.create({})(Submission);

export default SubmissionForm;
