import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { ThemeContext } from '../layouts';
import Hero from '../components/Hero';
import Seo from '../components/Seo';
import { Col, Row } from 'antd/lib/grid';
import { Button, List, Typography } from 'antd';
import Submission from '../components/Submission';

import 'antd/lib/grid/style/index.css';
class LandingPage extends React.Component {
    separator = React.createRef();

    scrollToContent = (e) => {
        this.separator.current.scrollIntoView({
            block    : 'start',
            behavior : 'smooth'
        });
    };

    render() {
        const message = {
            text: "JOIN OUR NEXT C60 COHORT, AND HACK YOUR WAY BACK TO HEALTH"
        }

        const listdata = [
            '1. You are not alone. You will track the 60 days with a cohort of 10-12 other people.',
            '2. Access to Commonality app to daily track your symptoms.',
            '3. Food tracking app that enables tracking at the nutrient level.',
            '4. Regular check-in from the Commonality team to ensure you are going ok.',
            '5. Analysis of your data using advanced machine learning algorithms to look for factors effecting your health.',
            '6. Tools to assess your current potential range of interventions.',
          ];


        const {
            data : {
                sectionbotImage : { resize: { src: sectionbotImgBg } },
                section5Image : { resize: { src: section5ImgBg } },
                sectionImage  : { resize: { src: sectionImgBg } },
                section2Image : {
                    resize : { src: section2ImgBg } // the new image with filename section2-bakckground.png
                },
                image1        : { resize: { src: testimonyPersonImg } },
                image2        : { resize: { src: testimonyPerson2Img } },
                bgDesktop     : { resize: { src: desktop } },
                bgTablet      : { resize: { src: tablet } },
                bgMobile      : { resize: { src: mobile } },
                site          : { siteMetadata: { facebook } }
            }
        } = this.props;

        const backgrounds = {
            desktop,
            tablet,
            mobile
        };

        return (
            <React.Fragment>
                <ThemeContext.Consumer>
                    {(theme) => (
                        <Hero
                            scrollToContent={this.scrollToContent}
                            backgrounds={backgrounds}
                            theme={theme}
                            message={message}
                        />
                    )}
                </ThemeContext.Consumer>
                <hr ref={this.separator} />
                <div id="section1" className="section section1">
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                            <div className="separator " />
                            <h2>
                                C60 IS SIXTY DAYS OF TRACKING YOUR DIET AND SYMPTOMS WITH ANALYSIS VIA POWERFUL MACHINE LEARNING.
                            </h2>
                            <div className="narrative">
                                <p>
                                    Has the medical establishment have run out
                                    of ideas to help you? Commonality.me is for
                                    you.
                                </p>
                                <p>
                                    No-one knows your body better than you do.
                                    We give you the smart tools to become your
                                    own body's Chief Scientist. We call it{' '}
                                    <i>BodyHacking</i> and it moves you from
                                    being the recipient of a label, to an active
                                    explorer of solutions.{' '}
                                </p>
                                <p>
                                    If you suffer from chronic illness, you are
                                    a HERO! We want to give you the smart tools
                                    (backed with the latest machine learning)
                                    for some super powers to hack your way back
                                    to health. Ready?
                                </p>
                                <div className="separator " />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="section2" className="section section2">
                    <Row>
                        <Col xs={{ span: 4}} lg={{ span:16 }}>
                            <div className="section2-img-bg" />
                        </Col>
                        <Col xs={{ span: 16}} lg={{ span:8 }}>
                            <div className="section2-content">
                                <div className="separator " />
                                <h2>CHRIS' STORY</h2>
                                <h3>12 YEARS SUFFERED CHRONIC ILLNESS</h3>
                                <div className="narrative">
                                    <p>
                                        Chris suffered from chronic illness for
                                        twelve years. Twelve years of pain,
                                        suffering and life passing him by.
                                    </p>
                                    <p>
                                        But after trying countless doctors,
                                        naturopaths and diets, it was only by
                                        using machine learning that he
                                        discovered a readily available
                                        nutritional component that was they key
                                        to recovering his health.
                                    </p>
                                    <p>
                                        Chris became his own body hacker, his
                                        own Chief Scientist, and was eventually
                                        healed. We give sufferers the tools to
                                        do what Chris did and become their own
                                        body hacker.
                                    </p>
                                    <div className="separator" />
                                    {/* <Button href="/story">READ MORE</Button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="section5" className="section">
                    <Row>
                        <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 3 }}>
                            <div className="section5" />
                        </Col>
                        <Col offset={3} span={8}>
                            <div className="separator" />
                            <div className="content">
                                <h2>THE C60 PROGRAM</h2>
                                <h3>BECOME YOUR OWN BODY'S CHIEF SCIENTIST</h3>
                                <div className="narrative">
                                    <p>
                                        For 60 days you will track what you eat, how you feel (the level of your symptoms), how active you've been and quality of sleep.
                                    </p>
                                    <p>
                                        At the end of the 60 days, Commonality.me will analyse your data with intelligent machine learning to determine what factors might be affecting you the most.
                                    </p>
                                </div>
                                <div className="separator " />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div id="section3" className="section section3">
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                            <div className="content">
                                <div className="separator" />
                                <h2>IS LIFE PASSING YOU BY?</h2>
                                <h3>TIME TO TAKE ACTION.</h3>
                                <div className="narrative">
                                    <p>
                                        It's not fair. That's what we think: if
                                        life is passing you by, it's not fair.
                                    </p>
                                    <p>
                                        But we also understand that some days
                                        are just really tough. We want to help
                                        you move to taking regular action - and
                                        body hack! You're not alone.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={2}>
                            <div className="form-submission">
                                <Submission />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="testimonies">
          <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
              <div className="testimony testimony-1">
                <Row>
                  <Col span={8}>
                    <div className="testimony-image1 testimony-image" />
                  </Col>
                  <Col span={16}>
                    <div className="bar" />
                    <div className="quotes">
                      <div className="quote-header">{`"`}</div>
                      <div className="quote-body">
                        I'm the kind of person who just tries to push ahead. Tracking my symptoms has been very useful to take stock.
                      </div>
                      <div className="attribution">
                        <div>
                          Ellie McConnell<br />
                          Perth
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 13 }}>
              <div className="testimony testimony-2">
                <Row>
                  <Col span={8}>
                    <div className="testimony-image2 testimony-image" />
                  </Col>
                  <Col span={16}>
                    <div className="bar" />
                    <div className="quotes">
                      <div className="quote-header">{`"`}</div>
                      <div className="quote-body">
                        Going through the 60 days of tracking with other has made all the difference. We learn from each other.
                      </div>
                      <div className="attribution">
                        <div>
                          Jane Richards<br />
                          Albany
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

                <div id="section-bot" className="section">
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 3 }}>
                            <div className="sectionbot-img-bg" />
                        </Col>

                        
                        <Col offset={3} span={8}>
                            <div className="separator" />
                            <div className="content">
                                <h2>THE C60 PROGRAM</h2>
                                <h3>SMART TOOLS THAT MAKE IT DOABLE</h3>
                                <div className="narrative">
                                    <p>
                                        Daily tracking can be hard work, but we provide easy tools, and the encouragement of doing C60 with other fellow travellers.
                                    </p>
                                    <p>
                                        Have more questions?
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="section section1" >
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                            <div className="separator" />
                            <h2>
                                C60 IS SIXTY DAYS OF TRACKING YOUR DIET AND SYMPTOMS WITH ANALYSIS FROM POWERFUL MACHINE LEARNING.
                            </h2>
                            
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                            <List
                            size="large"
                            header={<div><h3>INCLUDED IN COMMONAITY C60</h3></div>}
                            footer={<div><h3>ALL INCLUDED</h3></div>}
                            bordered
                            dataSource={listdata}
                            renderItem={item => (<List.Item><h4>{item}</h4></List.Item>)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                            <div className="separator" />
                            <h2>
                                SIGN UP NOW AND YOU CAN BECOME YOUR OWN CHIEF SCIENTIST.
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={2}>
                            <div className="form-submission">
                                <Submission />
                            </div>
                        </Col>
                    <Row>
                        <div className="separator " />
                    </Row>
                    </Row>
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                        <h2>SPECIAL $99 PRICING FOR BETA PERIOD</h2>
                        <p>While we are in Beta, introductory offer for C60 is $99 (normally $299)</p>
                        <p>Payment is 100% refundable at the end of C60. If you don't get value, we will return your money.</p>
                        <p>.</p>
                        </Col>
                    </Row>
                    
                </div>

                <hr ref={this.separator} />
                <hr ref={this.separator} />
                <hr ref={this.separator} />
                <Seo facebook={facebook} />
                <style jsx>{`
                    hr {
                        margin: 0;
                        border: 0;
                    }
                    .section2-img-bg {
                        width: 100%;
                        height: 804px;
                        background-image: url(${section2ImgBg});
                        background-size: cover;
                        color: #000;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: center;
                    }


                    #section3 {
                        background-image: url(${sectionImgBg});
                        background-size: cover;
                        height: 804px;
                    }

                    .section h3 {
                        margin: 0 0 60px;
                    }
                    .section h2 {
                        margin: 0 0 6px;
                    }

                    .separator {
                        display: block;
                        width: 42px;
                        height: 6px;
                        box-shadow: inset 0 4px 0 0 #cacaca;
                        margin: 114px auto 24px;
                    }

                    .section1 {
                        min-height: 702px;
                        position: relative;
                        text-align: center;
                    }

                    .section1 h2 {
                        margin: 0 0 96px;
                    }

                    .narrative {
                        margin-bottom: 0;
                        text-align: left;
                        color: #1b1b1b;
                        opacity: 0.8;
                    }

                    .narrative p {
                        line-height: 24px;
                        margin: 0;
                    }

                    .narrative p + p {
                        margin-top: 24px;
                    }

                    .section2 {
                        background-color: #1b1b1b;
                    }
                    .section2,
                    .section2 .narrative,
                    .section3,
                    .section3 .narrative {
                        color: #e3e7eb;
                    }

                    .section2 .separator {
                        margin: 102px 6px 12px 0;
                        position: relative;
                        left: 1px;
                    }

                    .section2 .section2-content {
                        padding: 15px;
                    }

                    .section3 .separator {
                        margin: 108px 6px 12px 0;
                        position: relative;
                        left: 1px;
                    }
                    .section3 {
                    }
                `}</style>
                <style jsx>{`
                    .testimonies .bar {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    .testimonies .bar:before {
                        display: block;
                        content: "";
                        width: 500%;
                        height: 1000px;
                        position: absolute;
                        transform: translatex(-15px) rotate(13.49573deg)
                            translatey(-7px);
                        transform-origin: 0 0;
                        background: #f8f8f8;
                    }

                    .testimonies .testimony-image1 {
                        background-image: url(${testimonyPersonImg});
                        filter: saturate(80%);
                        -webkit-filter: saturate(80%);
                        -moz-filter: saturate(80%);
                        -o-filter: saturate(80%);
                        -ms-filter: saturate(80%);
                        width: 200px;
                        background-position: 65% 0;
                        background-size: auto;
                    }
                    .testimonies .testimony-image2 {
                        background-image: url(${testimonyPerson2Img});
                        filter: saturate(80%);
                        -webkit-filter: saturate(80%);
                        -moz-filter: saturate(80%);
                        -o-filter: saturate(80%);
                        -ms-filter: saturate(80%);
                        width: 200px;
                        background-position: 65% 0;
                        background-size: auto;
                    }
                    .quotes {
                        padding-left: 24px;
                    }
                    .quotes .quote-header {
                        font-size: 65px;
                        font-weight: bolder;
                        padding-top: 5px;
                        color: #f28500;
                        position: relative;
                        left: -6px;
                    }
                        .quotes .quote-body {
                            color: rgba(27, 27, 27, 0.8);
                            margin-bottom: 24px;
                            position: relative;
                            color: rgba(27, 27, 27, 0.8);
                            margin-bottom: 24px;
                    }

                    .quotes .attribution {
                        color: rgba(27, 27, 27, 0.8);
                    }

                    .quotes .attribution div {
                        font-size: 16px;
                        chrome-offset: 16px;
                        top: 2px;
                        letter-spacing: 0.2px;
                        position: relative;
                        line-height: 24px;
                    }
                    .testimony-1 {
                        margin-top: 132px;
                    }
                    .testimonies {
                        margin-bottom: 132px;
                    }

                    .testimony-image {
                        position: absolute;
                        left: 0;
                        background-repeat: no-repeat;
                        height: 252px;
                        top: 0;
                    }

                    .testimonies .quote-large {
                        font-size: 90px;
                    }
                    .testimony {
                        min-height: 252px;
                        overflow: hidden;
                        border-radius: 4px;
                        background-color: #f8f8f8;
                        box-shadow: 0 40px 65px -24px rgba(0, 0, 0, 0.25);
                        transition: 1s;
                        position: relative;
                    }
                `}</style>
                <style jsx>{`
                    .section5 {
                        height: 700px;
                        width: 100%;
                        background-image: url(${section5ImgBg});
                    }
                    .sectionbot-img-bg {
                        height: 700px;
                        width: 100%;
                        background-image: url(${sectionbotImgBg});
                    }

                `}</style>
            </React.Fragment>
        );
    }
}

LandingPage.propTypes = {
    data : PropTypes.object.isRequired
};

export default LandingPage;

//eslint-disable-next-line no-undef
export const query = graphql`
    query LandingQuery {
        posts: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
            sort: { fields: [fields___prefix], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        prefix
                    }
                    frontmatter {
                        title
                        slug
                        category
                        author
                        cover {
                            children {
                                ... on ImageSharp {
                                    fluid(maxWidth: 800, maxHeight: 360) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                facebook {
                    appId
                }
            }
        }
        sectionImage: imageSharp(
            fluid: { originalName: { regex: "/section-background/" } }
        ) {
            resize(width: 1200, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        section2Image: imageSharp(
            fluid: { originalName: { regex: "/section2-background/" } }
        ) {
            resize(width: 1200, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        section5Image: imageSharp(
            fluid: { originalName: { regex: "/tracking-image/" } }
        ) {
            resize(width: 600, height: 900, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        sectionbotImage: imageSharp(
            fluid: { originalName: { regex: "/commonality-bot/" } }
        ) {
            resize(width: 600, height: 900, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgDesktop: imageSharp(
            fluid: { originalName: { regex: "/landing-background/" } }
        ) {
            resize(width: 1200, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgTablet: imageSharp(
            fluid: { originalName: { regex: "/landing-background/" } }
        ) {
            resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgMobile: imageSharp(
            fluid: { originalName: { regex: "/landing-background/" } }
        ) {
            resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        image1: imageSharp(fluid: { originalName: { regex: "/image-woman/" } }) {
            resize(width: 200, height: 252, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        image2: imageSharp(fluid: { originalName: { regex: "/image-jane/" } }) {
            resize(width: 200, height: 252, quality: 90, cropFocus: CENTER) {
                src
            }
        }
    }
`;

//hero-background
